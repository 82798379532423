@import '@/styles/variables.scss';































































































































































































































































.bav-info {
  padding-top: 33px !important;
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab:not(.v-tabs--vertical .v-tab) {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 9px;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .bav-info__tab-text {
        color: #2830ce;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .bav-info {
    .v-tabs-bar {
      //    height: 50px !important;

      border-radius: 5px;
    }
    .v-tab {
      //   max-width: 50px;
      line-height: 12px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    .v-slide-group__wrapper {
      tr td {
        display: none;
      }
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      // padding: 0;
    }
  }
}
