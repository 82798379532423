@import '@/styles/variables.scss';











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































svg.scenario-a {
  max-width: 336px;
  width: 100%;
  padding-top: 7px;
}
