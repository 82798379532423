@import '@/styles/variables.scss';


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































svg.scenario-b {
  max-width: 350px;
  width: 100%;
  padding-top: 7px;
}
