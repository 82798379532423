@import '@/styles/variables.scss';

































































































.scenario {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;

    color: #2830ce;
  }
  &--b {
    padding-right: 95px;
  }
  &-img {
    // height: 339px;
    // width: 226px;

    &--a {
      max-width: 336px;
      width: 100%;
      margin-right: 132px;
    }
    &--b {
      margin-right: 0;
      margin-left: 46px;
      // width: 263px;
      // height: 336px;
      max-width: 350px;
      width: 100%;
    }
    &--c {
      // width: 252px;
      // height: 393px;
      margin-right: 130px;
    }
  }
  &-text {
    max-width: 347px;
  }
}

@media screen and (max-width: 960px) {
  .scenario {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px !important;
    margin-top: 8px;
    &--b {
      padding-right: 5px;
    }
    &-img {
      // width: 124px;
      // height: 186px;
      margin-right: 44px;
      margin-top: 33px;

      &--b {
        margin-right: 0;
        margin-left: 15px;
        // width: 143.95px;
        // height: 183.91px;
      }
      &--c {
        // width: 119.25px;
        // height: 193.24px;
        margin-right: 48px;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      max-width: 158.07px;
    }
  }
}
