@import '@/styles/variables.scss';




















































































































































































.provider {
  &-logo {
    height: 65px;
    max-width: 250px;
    object-fit: contain;
    object-position: left;
  }
}
.v-image--custom {
  background: #fafafa;
}
.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

.insurance-plan {
  &-m {
    .v-expansion-panel {
      border-radius: 0 !important;
    }
  }
  &-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #505a68;
    margin-bottom: 32px;
    margin-top: 15px;
    margin-left: 19px;
  }
  &-benefit {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    padding-left: 19px;
    padding-right: 19px;
  }
  &-links {
    border-top: 1px solid #f3f3f3;
    padding: 6px 16px;
  }
  &-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2830ce;
    margin: 12px 0;
  }

  .v-expansion-panel-header--active {
    border-bottom: 1px solid #f3f3f3 !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
